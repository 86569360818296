import { ConfigProvider } from "antd";
import "./App.css";
import { Login } from "./Login";

function App() {
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#5cba1e",
          },
        }}
      >
        <Login />
      </ConfigProvider>
    </div>
  );
}

export default App;
